import React, { useEffect, useState } from "react";
import { toLocalTime } from "../../../../../components/ex_dayjs";
import { Button, Grid } from "@mui/material";
import SkipNextIcon from '@mui/icons-material/SkipNext';
import MDialog from "../../../../../components/controls/MDialog";
import {
    Create, TextInput, required,
    BooleanInput,
    SelectInput,
    SaveButton,
    SimpleForm,
    minLength,
    maxLength,
    useNotify,
} from 'react-admin';
import { useFormContext } from 'react-hook-form';
import { convertDataForMSelect } from "../../../../../utils/common";

const TRACKING_NAME = 'ProcessTracking';

const convertObjToArray = (obj) => {
    if (!obj) return [];
    let rs = [];
    Object.entries(obj).map((m) => {
        rs.push({ id: m[0], name: m[1] });
    });
    return rs;
}

export const onConfigTrackingProcess = (order, internalStatus, notes) => {
    if (order?.primary_account && !order.primary_account.includes('@')) return false;
    let ignoredAutoNotes = ["Clicked process button", "SEC ", "PRIMARY ", "Changed in-hand-date", "Shipped ", "Changed status", "PROCESS TRACKING"];
    let isShowTracking = order
        && order.event_market != 'Exact'
        && internalStatus == '1'
        && order.internal_status != '1' /*Available Unshipped */
        && order.internal_status != '2' /*Delayed Unshipped */
        && order.internal_status != '4' /*Completed */
        // && order.order_fullnotes
        && (notes || []).some(x => x.email.includes('@') && (x.note_type == 0 || x.note_type == -99) && !ignoredAutoNotes.some(n => x.post.startsWith(n)));

    let orderProcess;
    if (order.ordersProcessTracking) {
        orderProcess = order.ordersProcessTracking.find(x => x.category == TRACKING_NAME);
        currentOrder.orderProcessTracking = orderProcess; // to update orderProcessTracking into order object
    }
    return {
        isShow: order && !!orderProcess || isShowTracking,
        required: isShowTracking && order && !orderProcess
    }
}

const FormContent = ({ data, onSkip, isSaveOrder, handleClose }) => {
    const { record, processTrackingDics, processTrackingErrs } = data;
    const { setValue, getValues } = useFormContext();
    const [ipRequired, setIPRequired] = useState({});
    const [isOtherError, setOtherError] = useState(false);

    const [dictionary, setDictionaries] = useState({
        ipTypes: [],
        browsers: [],
        accountTypes: [],
        cardBrands: []
    });

    const trimmedRequired = [
        required(),
        (value) => {
            if (value && typeof value === 'string' && value.trim() === '') {
                return 'This field cannot be null or contain only whitespace.';
            }
            return undefined;
        }
    ];
    useEffect(() => {
        setDictionaries((prev) => ({
            ...prev,
            ipTypes: convertObjToArray(processTrackingDics.ip_types),
            browsers: convertObjToArray(processTrackingDics.browsers),
            cardBrands: convertObjToArray(processTrackingDics.card_brands),
            accountTypes: convertDataForMSelect(processTrackingDics.account_types)
        }));
        if (record.otherError) setOtherError(true);
        if (record.remote_Comp) setIPRequired({ validate: trimmedRequired });
    }, [record]);

    const onChangeBrowser = (e) => {
        const { value } = e.target;
        if (value == 'Stealth') { // Select Hydraproxy for ip_type
            setValue('ip_Type', dictionary.ipTypes.find(x => x.id == 'Hydraproxy').id);
        }
    }

    const onChangeError = (e) => {
        if (e) {
            const { value } = e.target;
            if (value == 'other') {
                setValue('otherError', '');
                setOtherError(true);
                return;
            }
        }
        setValue('otherError', '');
        setOtherError(false);
    }

    const onChangeRemoteComp = (e) => {
        const { checked } = e.target;
        if (checked) setIPRequired({ validate: trimmedRequired });
        else setIPRequired({});
    }

    const handleSkip = () => {
        const v = getValues();
        onSkip(v);
    }

    return (
        <>
            <Grid item container xs={12} style={{ marginTop: -20 }}>
                <span><b>Author:&nbsp;</b>{record.author}&nbsp;|&nbsp;<b>Tracking Time:&nbsp;</b>{record.tracking_time}</span>
            </Grid>
            {/* Row 1 */}
            <Grid container spacing={2}>
                <Grid item xs={6}>
                    <TextInput source="ip" {...ipRequired} resettable />
                </Grid>
                <Grid item xs={6}>
                    <SelectInput source="browser" validate={required()} choices={dictionary.browsers} onChange={onChangeBrowser} translateChoice={false} resettable />
                </Grid>
            </Grid>
            {/* Row 2 */}
            <Grid container spacing={2}>
                <Grid item xs={6}>
                    <SelectInput label="IP Type" source="ip_Type" choices={dictionary.ipTypes} translateChoice={false} resettable value={record.ip_Type} />
                </Grid>
                <Grid item xs={6} style={{ marginTop: 15, float: 'right' }}>
                    <BooleanInput label="Incognito Browser" source="incognito_Browser" />
                </Grid>
            </Grid>
            {/* Row 3 */}
            <Grid container spacing={2}>
                <Grid item xs={6}>
                    <SelectInput label="Card Brand" source="card_Brand" validate={required()} choices={dictionary.cardBrands} translateChoice={false} resettable />
                </Grid>
                <Grid item xs={6}>
                    <BooleanInput label="Same Billing/Venue Location" source="same_Billing_Venue" />
                </Grid>
            </Grid>
            {/* Row 4 */}
            <Grid container spacing={2}>
                <Grid item xs={6}>
                    <SelectInput label="Account Type" source="account_Type" validate={required()} choices={dictionary.accountTypes} translateChoice={false} resettable />
                </Grid>
                <Grid item xs={6}>
                    <TextInput label="Other State" source="other_State" validate={[required(), minLength(2), maxLength(2)]} placeholder="Format required: 2 letters. For instance, TX  not Texas" resettable />
                </Grid>
            </Grid>
            {/* Row 5  */}
            <Grid container spacing={2}>
                <Grid item xs={6}>
                    <SelectInput source="error" validate={required()} choices={processTrackingErrs} onChange={onChangeError} translateChoice={false} resettable />
                </Grid>
                <Grid item xs={6}>
                    <BooleanInput label="Remote Comp" source="remote_Comp" onChange={onChangeRemoteComp} />
                </Grid>
            </Grid>
            {isOtherError && (
                <Grid container>
                    <TextInput source="otherError" validate={trimmedRequired} value={record.otherError} multiline minRows={2} resettable />
                </Grid>
            )}
            <Grid container justifyContent={"flex-end"} spacing={1} style={{ marginBottom: -30 }}>
                {isSaveOrder && <Grid item><Button startIcon={<SkipNextIcon />} onClick={handleSkip} variant='outlined'>Skip & Save Order</Button></Grid>}
                <Grid item><SaveButton variant="contained" color="primary" label={isSaveOrder ? 'Save Tracking & Order' : 'Save Tracking'} /></Grid>
                <Grid item><Button onClick={handleClose} variant='outlined' aria-label={'h_isOpen'}>Close</Button></Grid>
            </Grid>
        </>
    );
}

const OrderProcessTrackingPopup = ({ vendor, dataProvider, data, callback, onClose }) => {
    const { order } = data;
    const { h_isOpen, h_isSaveOrder } = data.dlgState;
    const [isLoading, setLoading] = useState(false);
    const [trackingItem, setTrackingItem] = useState({});
    const [listError, setListError] = useState([]);
    const notify = useNotify();
    const saveCallback = data.dlgState.callback || callback;

    useEffect(() => {
        if (order && h_isOpen) {
            let trackingErrors = (order.orderProcessTrackingErrors || []).filter(x => x.category == TRACKING_NAME).map(x => ({ id: x.error, name: `${x.market}: ${x.error}` }));
            trackingErrors.push({ id: 'other', name: 'Other' });
            setListError(trackingErrors);
            let record = {
                order_id: order.id,
                order_number: order.order_number,
                category: TRACKING_NAME
            };
            if (order.orderProcessTracking) {
                record = { ...record, ...order.orderProcessTracking };
                record.tracking_time = record?.utc_Posted_On ? toLocalTime(record.utc_Posted_On).format('MMM D YYYY hh:mm A') : '';
                record.otherError = null;
                let selectedError = trackingErrors.find(x => x.id == record.error);
                let _error = selectedError && selectedError.id;
                if (!_error) {
                    record.otherError = record.error;
                    record.error = 'other';
                }
            }
            setTrackingItem(record);
        }
    }, [order, h_isOpen]);

    const onSubmit = (data) => {
        if (data.otherError) {
            data.error = data.otherError.trim();
        }
        setLoading(true);
        dataProvider.postData(vendor.controller, `SaveProcesTracking`, data)
            .then((response) => {
                setLoading(false);
                if (response && response.data.success) {
                    onClose(null, 'h_isOpen', false);
                    notify(`Saved Tracking successfully!`, { type: 'success', autoHideDuration: 1000 });
                    saveCallback(response.data.orderProcess);
                }
            });
    }

    const onSkip = () => {
        onClose(null, 'h_isOpen', false);
        saveCallback();
    }

    return (
        <>
            {h_isOpen && (
                <Create record={trackingItem} resource="Vivid" >
                    <MDialog
                        title={`PROCESS TRACKING #${trackingItem.order_number}`}
                        maxWidth={'md'}
                        open={h_isOpen}
                        handleClose={onClose}
                        name={'h_isOpen'}
                        footer={false}
                        disabled={isLoading}
                        msgLoading={'Saving...'}
                        className={'order-dialog'}
                        children={
                            <SimpleForm defaultValues={order.orderProcessTracking || {}} toolbar={null} mode="onBlur" reValidateMode="onBlur" onSubmit={onSubmit}>
                                <FormContent
                                    handleClose={onClose}
                                    onSkip={onSkip}
                                    isSaveOrder={h_isSaveOrder}
                                    data={{
                                        record: trackingItem,
                                        processTrackingDics: order.orderProcessTrackingDictionaries || {},
                                        processTrackingErrs: listError
                                    }}
                                />
                            </SimpleForm>
                        }
                    >
                    </MDialog>
                </Create>
            )}
        </>
    )
}
export default OrderProcessTrackingPopup;