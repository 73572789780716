import SocketIO from "./socket_io";
import { dayjs } from "../../../components/ex_dayjs";

const initSocketIO = (app) => {
    const { options, vendor, bus, bus_events, setEditingOrders } = app;
    console.log('_app', app);

    onRefreshUI = function (order) {
        console.log("*** onRefreshUI ***", order);
    };

    onOpeningOrders = (function () {
        var colourAssigns = {};
        function getOrAssign(by) {
            if (colourAssigns.hasOwnProperty(by)) {
                return colourAssigns[by];
            } else {
                colourAssigns[by] = (Object.keys(colourAssigns).length % 18) + 1;
            }
            return colourAssigns[by];
        }

        return function (orders) {
            Object.keys(orders).forEach(by => {
                var o = orders[by];
                var boxData = { By: by === options.env.Identity ? "You" : o.nickName, DateTime: dayjs().fromNow(), Color: getOrAssign(by) };
                if (by === options.env.Identity)
                    console.log(`*** onOpeningOrders *** boxData - self`, boxData);
                o.boxData = boxData;
            });
            setEditingOrders(Object.entries(orders).map(x => x[1]));
        };
    })();

    var socketIO = new SocketIO({
        identity: options.env.Identity,
        nickName: options.env.FirstName
    });
    socketIO.connect(vendor.getSocketIOEndPoint(options.ioUrl)).then(function () {
        socketIO.on(bus_events.opening_orders, onOpeningOrders);
        socketIO.on(bus_events.order_status_changed, bus.emit.bind(bus, bus_events.order_status_changed));
        socketIO.on(bus_events.broadcast, bus.emit.bind(bus));
        bus.ioUrl = socketIO.url;
    }).catch(console.error);

    bus.on(bus_events.grid_selection_changed, function onAnOrderOpened(order, status) {
        socketIO.open(order, status);
    });
    bus.on(bus_events.grid_before_send, function () {
        //$(".box-template").remove();
        return socketIO.emit("revoke");
    });
    bus.on(bus_events.grid_completed, socketIO.refresh);
    bus.on(bus_events.refresh_highlight_box, onRefreshUI);
    bus.on(bus_events.order_status_changing, function onOrderStatusChanging(request) {
        return socketIO.updateStatus(request);
    });

    bus.on(bus_events.broadcast, function (msg, data) {
        socketIO.emit(bus_events.broadcast, msg, data, options.env.Identity);
    });
    var timerResize = 0;
    // $(window).on('resize', function () {
    //     clearTimeout(timerResize);
    //     setTimeout(onRefreshUI, 20);
    // });
};

export { initSocketIO };