
/**
 * Parses a modified JSON file with an "inBOnly" key.
 * @param {string} filePath
 * @returns {Array<Object>} Array of seat objects.
 */
function parseModifiedSeatData(data) {
    const modifiedData = data.inBOnly || {};
    const result = [];

    for (const section in modifiedData) {
        const rowsData = modifiedData[section];
        for (const row in rowsData) {
            const seats = rowsData[row];
            seats.forEach(seat => {
                result.push({
                    Section: section,
                    Row: row,
                    SeatName: seat.name,
                    // Assume available if not provided.
                    Available: "Y",
                    Price: seat.price
                });
            });
        }
    }
    return result;
}

/**
 * Parses an original JSON file with a "Seats" key.
 * @param {string} filePath
 * @returns {Array<Object>} Array of seat objects.
 */
function parseOriginalSeatData(data) {
    const seatData = data.Seats || [];
    return seatData.map(seat => ({
        Section: seat.Sec,
        Row: seat.Row,
        SeatName: seat.SeatName,
        Available: seat.Available,
        Price: seat.Price
    }));
}
/**
 * Processes an array of seat objects.
 * Filters for available seats, groups by Section, Row, and Price,
 * and sorts the output accordingly.
 * @param {Array<Object>} data - Array of seat objects.
 * @returns {Array<Object>} Consolidated and sorted data.
 */
function processData(data) {
    // Filter for available seats only
    const availableData = data.filter(item => item.Available === 'Y');

    // Group by Section, Row, and Price, counting the seats in each group.
    const grouped = {};
    availableData.forEach(item => {
        const key = `${item.Section}_${item.Row}_${item.Price}`;
        if (!grouped[key]) {
            grouped[key] = { Section: item.Section, Row: item.Row, Price: item.Price, "Number of Seats": 0 };
        }
        grouped[key]["Number of Seats"] += 1;
    });

    // Convert the grouped data into an array.
    const consolidatedData = Object.values(grouped);

    // Sort by Section, then Row, then Price
    consolidatedData.sort((a, b) => {
        if (a.Section < b.Section) return -1;
        if (a.Section > b.Section) return 1;
        if (a.Row < b.Row) return -1;
        if (a.Row > b.Row) return 1;
        return a.Price - b.Price;
    });

    return consolidatedData;
}

function getConsolidatedData(data) {
    let parsedData = null;
    if (data.Seats) {
        console.log(`Identified as original data`);
        parsedData = parseOriginalSeatData(data);
    } else if (data.inBOnly) {
        console.log(`Identified as modified data`);
        parsedData = parseModifiedSeatData(data);
    } else {
        console.log(`Unknown data type. Skipping.`);
        return [];
    }
    return processData(parsedData);
}

module.exports = { getConsolidatedData };