import React, { useEffect, useState } from "react";
import RuleEditPopup from "../../../../csv-merge/components/RuleEditPopup";
import { loadResourceCSV } from "../../../../../utils/common";
import LoadingButton from "../../../../../components/controls/LoadingButton";

const DMQPopup = ({ vendor, dataProvider, data }) => {
    const { order } = data;
    const [isOpen, setOpen] = useState(false);
    const [rule, setRule] = useState();
    const [source, setSource] = useState();

    useEffect(() => {
        loadResourceCSV(dataProvider, (response) => setSource(response));
    }, []);

    async function getData() {
        let response = await dataProvider.postData(vendor.controller, `GetDMQTool`, order);
        if (response && response.data) {
            let respOrder = response.data.order;
            setOpen(true);
            setRule({
                sources: source.filter(x => respOrder.listing_accounts?.some(y => y == x.Id || y == x.Name)).map(x => x.Name),
                event: respOrder.event,
                venue: respOrder.listing != null ? respOrder.listing.venue : respOrder.venue,
                quantity: respOrder.listing != null ? respOrder.listing.quantity : respOrder.quantity,
                tags: [''],
                priority: 1,
                expiredDate: respOrder.date,
                action: {
                    method: 'modify',
                    rules:
                        [{
                            id: 'Quantity',
                            field: 'Quantity',
                            type: 'string',
                            input: 'text',
                            operator: 'assign',
                            value: '=x-2'
                        }]
                }
            });
        }
    }

    return (
        <>
            <LoadingButton
                size="small"
                text=""
                variant="outlined"
                onClick={getData}
            >
                DMQ
            </LoadingButton>
            {isOpen && rule &&
                <RuleEditPopup dataProvider={dataProvider}
                    onClose={() => setOpen(false)}
                    // reloadPage={}
                    data={{
                        dlgName: 'dmq_isOpen',
                        isOpenRule: isOpen,
                        sources: source,
                        ruleId: [-1, rule]
                    }} />
            }
        </>
    )
}

export default React.memo(DMQPopup);