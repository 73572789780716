import React, { useEffect, useState } from "react";
import { useRecordContext } from "react-admin";
import { dayjs } from "../../../../components/ex_dayjs";
import { Grid, Tooltip } from "@mui/material";
import { editColor } from "../../../../utils/enum";
const OrderNotes = ({ editingOrders }) => {
    const record = useRecordContext();
    const [rowHeight, setRowHeight] = useState(0);

    var editOrder = editingOrders.find(x => x.orderNumber == record.order_number || x.orderNumber?.order_number == record.order_number);

    if (record.order_notes) {
        var title = record.order_notes.reduce((final, note) => {
            let item = `${note.author}: ${note.post} - ${dayjs.utc(note.utc_posted_on).fromNow()}`
            final.push(item);
            return final;
        }, []);
    }
   
    useEffect(() => {
        if (editOrder) {
            let _rowheight = getRowHeight(record.order_number);
            setRowHeight(_rowheight);
            // console.log(`order ${record.order_number} , row height = ${_rowheight}`);
        }
    })//re-calculate row-height after every render in grid: data change makes row height changes

    const getRowHeight = (order_number) => {
        var rowHeight = [...document.querySelectorAll('td.column-order_number')].find(x => x.innerText == order_number)?.offsetHeight;
        return rowHeight;
    }

    return (
        <Grid container spacing={1}>
            <Grid item xs={editOrder ? 11 : 12} sx={{ overflow: editOrder ? 'hidden' : '' }}>
                {title && <Tooltip title={title.join('. ')}>
                    <span className="orderNotes">
                        {record.order_notes.map((note, index) => {
                            return <span key={`quicknote-${record.order_number}-${index}`}>
                                <span>
                                    <b>{note.author}: </b>
                                    {note.post} - {dayjs.utc(note.utc_posted_on).fromNow()}
                                </span>
                                <br />
                            </span>;
                        })}
                    </span>
                </Tooltip>}
            </Grid>
            {editOrder && <Grid item xs={1} >
                <div className="highlight-by" style={{ background: `${editColor[editOrder.boxData.Color - 1]}`, width: rowHeight }}>
                    {editOrder.boxData.By}
                </div>
            </Grid>}


        </Grid>

    );
};
export default OrderNotes;