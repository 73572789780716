import { useEffect } from "react";
import React, { useState, useRef } from "react";
import { Dialog, DialogTitle, DialogContent, DialogActions, Button } from '@mui/material';
import { useAuthProvider, useNotify } from 'react-admin';
let lastReceivedOtp = ''; // this is good enough because this dialog has only one instance anyway
function LoginDialog({ authProvider }) {
    const [authState, setAuthState] = useState(null);
    const windowRef = useRef(window); // document here is window.document
    const documentRef = useRef(document); // document here is window.document
    const notify = useNotify();
    documentRef.current.addEventListener("devsoftAuthEvent", (e) => {
        e.preventDefault();
        // e is the event object; e.detail contains any data passed
        console.log("Received custom event devsoftAuthEvent:", e.detail);
        setAuthState(e.detail.authState);
    });
    windowRef.current.addEventListener("message", (e) => {
        e.preventDefault();
        const allowedOrigins = [
            "https://hello2.songtek.net",
            "https://hello.songtek.net",
            "http://localhost:3200",
            "https://localhost:3200",
        ];
        if (!allowedOrigins.includes(e.origin)) {
            console.log("Skipped message invalid origin:", e.origin);
            return;
        }
        const { name, otp } = e.data;
        if (name !== "otpReady") {
            console.log("Skipped message invalid name:", name);
            return
        }
        if (otp === lastReceivedOtp) {
            console.log("Skipped message. Reason: OTP already processed");
            return
        }
        // debugger;
        console.log('Received message otpReady from the iframe: ' + otp);
        authProvider.login({ otp })
            // .then(({ redirectTo }) => {
            //     navigate(redirectTo || "/", { replace: true });
            // })
            .catch((e) => {
                notify(e.message);
                // window.location.href = 'https://hello2.songtek.net/auth/?redirectToPath=/Goto/' + btoa(PUBLIC_URL);
            });
        lastReceivedOtp = otp;
    });


    const isOpen = !!authProvider && authState === 'unauthenticated';

    const handleClose = () => {
        setAuthState(null);
    };
    const helloUrl = process.env.HELLO_URL || 'https://hello2.songtek.net';
    const loginUrl = `${helloUrl}/RefreshToken`;

    return (
        <Dialog
            open={isOpen}
            onClose={handleClose}
            maxWidth="md"       // Makes the Dialog larger; choose "lg" if you need even more space
            fullWidth           // Makes the Dialog use the full width of the specified maxWidth
        >
            <DialogTitle>Your session has expired. Please login to continue</DialogTitle>
            <DialogContent dividers>
                {/* 
                  For a large enough iframe, adjust width/height as needed.
                  Also consider making it responsive by using CSS max-width, etc.
                */}
                <iframe
                    src={loginUrl}
                    style={{ width: '100%', height: '400px', border: 'none' }}
                    title="Login Iframe"
                />
            </DialogContent>
        </Dialog>
    );
}

export default LoginDialog;
