import React, { useState, useEffect } from 'react';
import { SelectInput, SimpleForm, useDataProvider, Button } from 'react-admin';
import MonacoEditor from '@monaco-editor/react';
import { getConsolidatedData } from './processor';
import _exporter from "../../exporter";
/**
 * Props for the DropdownTextEditor component
 */
interface DropdownTextEditorProps {
    bucketName: string;
    choices: { id: string; name: string; }[];
    onDataLoaded: (data: any) => void;
}

/**
 * DropdownTextEditor component
 * 
 * This component fetches a list of items and displays them in a dropdown menu.
 * Upon selecting an item, it fetches and displays the details of the selected item in a Monaco Editor.
 * 
 * @param {DropdownTextEditorProps} props - The props for the component.
 * @returns {JSX.Element} The DropdownTextEditor component.
 */
const DropdownTextEditor: React.FC<DropdownTextEditorProps> = ({ bucketName, choices, onDataLoaded }) => {
    const [selectedItem, setSelectedItem] = useState<string>('');
    const [content, setContent] = useState<string>('');
    const [loading, setLoading] = useState<boolean>(false);
    const dataProvider = useDataProvider();

    const handleChange = async (event: React.ChangeEvent<HTMLSelectElement>) => {
        const selectedId = event.target.value;
        setSelectedItem(selectedId);
        if (selectedId) {
            try {
                setLoading(true);
                const { json } = await dataProvider.fetch(`wasabi/get/${bucketName}/${selectedId}`);
                if (onDataLoaded) onDataLoaded(json);
                const beautifiedContent = JSON.stringify(json, null, 2); // Beautify JSON
                setContent(beautifiedContent);
            } catch (error) {
                console.error('Error fetching detail:', error);
            } finally {
                setLoading(false);
            }
        }
    };

    const exportData = async () => {
        if (!content) {
            return;
        }
        let data = JSON.parse(content);
        let consolidatedData = getConsolidatedData(data);
        if (consolidatedData.length < 1) {
            consolidatedData = [{
                Section: 'There is no data because no available seat'
            }];
        }
        _exporter(consolidatedData, ['Section', 'Row', 'Price', 'Number of Seats'], "ConsolidatedData");
    };

    return (
        <>
            {choices.length === 0 ? <div>No content...</div> : (
                <SimpleForm toolbar={false}>
                    <SelectInput
                        fullWidth
                        source="key"
                        label="Select one to display"
                        choices={choices}
                        optionText="name"
                        optionValue="id"
                        onChange={handleChange}
                        value={selectedItem}
                    />
                    <div style={{ width: '100%', marginTop: '1rem', height: '400px' }}>
                        <MonacoEditor
                            height="100%"
                            language="json"
                            value={content}
                            options={{ readOnly: true }}
                        />
                    </div>
                    <Button
                        label="Export"
                        onClick={exportData}
                        disabled={loading}
                        style={{ marginTop: '1rem' }}
                    />
                </SimpleForm>
            )}
        </>
    );
};

export default DropdownTextEditor;
