import * as React from 'react';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { FormGroup, Grid, Typography } from '@mui/material';

const MCheckboxGroup = React.memo((props) => {
    const { data, onChange, label, defaultValue, ...params } = props;
    const defaultStatus = [
        { id: 0, checked: null, indeterminate: true },
        { id: 1, checked: true, indeterminate: false },
        { id: 2, checked: false, indeterminate: false }
    ];

    const [dataFormatter, setDataFormatter] = React.useState([]);
    const [item, setItem] = React.useState([]);

    React.useEffect(() => {
        if (data) {
            let newData = data.map(cb => formatCheckBoxes(cb));
            setDataFormatter(newData);
        }
    }, [data]);

    React.useEffect(() => {
        if (dataFormatter.length) {
            let newDateFormatter = item.length ? item : dataFormatter;
            let newData = defaultValue ? newDateFormatter.map(cb => {
                let itemChanged = defaultValue.find(x => x.name == cb.name)
                if (itemChanged) return formatCheckBoxes(cb, itemChanged.value);
                return { ...cb };
            }) : newDateFormatter;
            setItem(newData);
        }
    }, [defaultValue, dataFormatter]);

    React.useEffect(() => {
        let values = getValue(item);
        onChange(values);
    }, [item]);

    function formatCheckBoxes(cb, defaultVal) {
        let nullable = typeof cb.nullable === 'undefined' ? false : cb.nullable; // default value = false
        let __defaultVal, hidden = cb.hidden;
        if (typeof defaultVal !== 'undefined') {
            hidden = false;
            __defaultVal = defaultVal;
        }
        else {
            __defaultVal = typeof cb.defaultValue === 'undefined' ? false : cb.defaultValue; // default value = false
            if (__defaultVal !== true) {
                if (!defaultValue) __defaultVal = false;
                else if (defaultValue.some(x => x.name === cb.name)) __defaultVal = true;
            }
        }
        // formatter....
        let allowNull = nullable;
        let statuses = defaultStatus.map((m) => Object.assign({}, m)); // clone array
        if (__defaultVal == null) allowNull = true;
        if (!allowNull) statuses.shift() // remove isnull;
        let defaultItem = statuses.find(x => x.checked == __defaultVal);
        let isPush = defaultItem.checked;//should add into filter or not
        let newCb = { ...cb, nullable, defaultValue: __defaultVal, allowNull, defaultItem, statuses };
        let _defaultItem = changeDefaultIfAny(newCb);
        if (_defaultItem) {
            defaultItem = _defaultItem;
        }
        return { ...newCb, isPush, hidden };
    }

    /**
     * First set default value, then change default value
     */
    function changeDefaultIfAny(checkbox) {
        if (checkbox.defaultItem.checked != null && checkbox.defaultValue == null ||
            checkbox.defaultItem.checked == null && checkbox.defaultValue != null
        ) {
            let next = checkbox.statuses.find(x => x.checked == checkbox.defaultValue);
            return next;
        }
    }
    function getValue(data) {
        let checkValues = data.reduce((final, item) => {
            if (item.defaultItem.checked != null && item.isPush) {
                final.push({ name: item.name, value: item.defaultItem.checked });
            }
            return final;
        }, []);
        return checkValues;
    }
    const handleChange = (event) => {
        const { checked, name } = event.target;
        var next;
        let cb = item.find(x => x.name == name);
        if (cb.allowNull) {
            var nextId = cb.defaultItem.id + 1;
            if (nextId > 2) nextId = 0;
            next = cb.statuses.find(x => x.id == nextId);
        } else {
            next = cb.statuses.find(x => x.checked == checked);
        }
        let isPush = true;
        if (!cb.allowNull && checked === false) isPush = false; // Case true or false: if value = false => ignore
        setItem((prev) => prev.map(cb => cb.name == name ? { ...cb, defaultItem: next, isPush } : cb));
    }

    return (
        <Grid container spacing={1} className={props.className}>
            <Grid item alignContent={'center'} sx={{ marginRight: '5px' }}>
                <label className="MuiFormLabel-root"><b>{label}</b></label>
            </Grid>
            <Grid item xs={10}>
                <FormGroup row={true}>
                    {
                        item.filter(x => !x.hidden).map(cb => {
                            return <FormControlLabel key={`key-${cb.name}`}
                                control={
                                    <Checkbox
                                        {...params}
                                        name={cb.name}
                                        size='small'
                                        checked={cb.defaultItem.checked}
                                        indeterminate={cb.defaultItem.indeterminate}
                                        onChange={handleChange}
                                        sx={{ margin: '-5px -5px -4px 0' }}
                                    />
                                }
                                label={<Typography variant='caption'>{cb.label}</Typography>}
                                sx={{ marginRight: '4px' }}
                            />
                        })
                    }
                </FormGroup>
            </Grid>
        </Grid>
    )
});
export default MCheckboxGroup;