import React, { useState, useEffect } from 'react';
import { Box, Typography } from '@mui/material';
import MonacoEditor from '@monaco-editor/react';
import { Button } from 'react-admin';
import { getConsolidatedData } from './processor';
import _exporter from "../../exporter";
interface Seat {
    Sec: string;
    Row: string;
    SeatName: string;
    Available: string;
    Price: number | null;
}

interface Data {
    Seats: Seat[];
}

interface DiffProps {
    a: Data | null;
    b: Data | null;
}

/**
 * calcDiff function
 * 
 * A function to calculate the difference between two JavaScript objects.
 * 
 * @param {Data} a - The first object.
 * @param {Data} b - The second object.
 * @returns {string} The result of the difference calculation in a readable format.
 */
const calcDiff = (a: Data, b: Data): string => {
    const diff: Record<string, any> = {
        inAOnly: {},
        inBOnly: {},
        differences: {}
    };

    const indexSeatsByKey = (seats: Seat[]) => {
        return seats.reduce((acc, seat) => {
            const key = `${seat.Sec}-${seat.Row}-${seat.SeatName}`;
            acc[key] = seat;
            return acc;
        }, {} as Record<string, Seat>);
    };

    const seatsA = indexSeatsByKey(a.Seats);
    const seatsB = indexSeatsByKey(b.Seats);

    // Find seats in A but not in B
    for (const key in seatsA) {
        if (!seatsB[key]) {
            const [sec, row] = key.split('-');
            if (!diff.inAOnly[sec]) diff.inAOnly[sec] = {};
            if (!diff.inAOnly[sec][row]) diff.inAOnly[sec][row] = [];
            diff.inAOnly[sec][row].push({ name: seatsA[key].SeatName, price: seatsA[key].Price });
        }
    }

    // Find seats in B but not in A
    for (const key in seatsB) {
        if (!seatsA[key]) {
            const [sec, row] = key.split('-');
            if (!diff.inBOnly[sec]) diff.inBOnly[sec] = {};
            if (!diff.inBOnly[sec][row]) diff.inBOnly[sec][row] = [];
            diff.inBOnly[sec][row].push({ name: seatsB[key].SeatName, price: seatsB[key].Price });
        }
    }

    // Find seats that are in both but have differences
    for (const key in seatsA) {
        if (seatsB[key]) {
            const seatA = seatsA[key];
            const seatB = seatsB[key];
            const seatDiff: Record<string, any> = {};

            if (seatA.SeatName !== seatB.SeatName || seatA.Price !== seatB.Price) {
                seatDiff.name = { a: seatA.SeatName, b: seatB.SeatName };
                seatDiff.price = { a: seatA.Price, b: seatB.Price };
            }

            if (Object.keys(seatDiff).length > 0) {
                const [sec, row] = key.split('-');
                if (!diff.differences[sec]) diff.differences[sec] = {};
                if (!diff.differences[sec][row]) diff.differences[sec][row] = [];
                diff.differences[sec][row].push(seatDiff);
            }
        }
    }

    return JSON.stringify(diff, null, 2);
};

/**
 * Diff component
 * 
 * This component takes two inputs `a` and `b` as objects, compares them using the `calcDiff` function,
 * and displays the result. If either input is not set, it prompts the user to select things to compare.
 * 
 * @param {DiffProps} props - The props for the component.
 * @returns {JSX.Element} The Diff component.
 */
const Diff: React.FC<DiffProps> = ({ a, b }) => {
    const [result, setResult] = useState<string>('');

    useEffect(() => {
        if (a && b) {
            a.Seats = a.Seats.filter(x => x.Available === 'Y');
            b.Seats = b.Seats.filter(x => x.Available === 'Y');
            setResult(calcDiff(a, b));
        } else {
            setResult('');
        }
    }, [a, b]);


    const exportData = async () => {
        let data = JSON.parse(result);
        let consolidatedData = getConsolidatedData(data);
        if (consolidatedData.length < 1) {
            consolidatedData = [{
                Section: 'There is no data because no available seat'
            }];
        }
        _exporter(consolidatedData, ['Section', 'Row', 'Price', 'Number of Seats'], "ConsolidatedData");
    };

    return (
        <Box sx={{ padding: 2 }}>
            <Typography variant="h6">Diff Component</Typography>
            {(!a || !b) && (
                <Typography color="error" sx={{ marginTop: 2 }}>
                    Please provide both objects to compare.
                </Typography>
            )}
            {result && (
                <Box>
                    <MonacoEditor
                        height="400px"
                        language="json"
                        value={result}
                        options={{ readOnly: true }}
                    />
                    <Button
                        label="Export"
                        onClick={exportData}
                        // disabled={loading}
                        style={{ marginTop: '1rem' }}
                    />
                </Box>
            )}
        </Box>
    );
};

export default Diff;
