import React, { useEffect, useState } from "react";
import { toLocalTime } from "../../../../../components/ex_dayjs";
import { Button, Grid, IconButton } from "@mui/material";
import ErrorIcon from '@mui/icons-material/Error';
import MDialog from "../../../../../components/controls/MDialog";
import {
    Create, TextInput, required,
    SelectInput,
    SaveButton,
    SimpleForm,
    useNotify,
} from 'react-admin';
import { useFormContext } from 'react-hook-form';

const TRACKING_NAME = 'ProblemTracking';
const tagsTracking = ['Fix'];

export const onConfigTrackingProblem = (order, tags) => {
    let isShow = tagsTracking.some(x => tags.includes(x));
    let orderProblem;
    if (order.ordersProcessTracking && !order.orderProblemTracking) {
        orderProblem = order.ordersProcessTracking.find(x => x.category == TRACKING_NAME);
        currentOrder.orderProblemTracking = orderProblem; // to update orderProblemTracking into order object
    }
    return isShow || !!orderProblem;
}

const FormContent = ({ data, handleClose }) => {
    const { record, processTrackingErrs } = data;
    const { setValue } = useFormContext();

    const [dictionary, setDictionaries] = useState({
        listErrors: [],
        listActions: []
    });

    useEffect(() => {
        let issue_category = data.error;
        if (issue_category) {
            var tmp = issue_category.split(':');
            data.issue_category = tmp[0];
            if (tmp[1]) setValue('description', tmp[1].trim());
        }
        setDictionaries((prev) => ({
            ...prev,
            listErrors: processTrackingErrs.map(x => x.trim()),
            listActions: ['Fixing', 'Fixed']
        }));
    }, [record]);

    return (
        <>
            <Grid item container xs={12} style={{ marginTop: -20 }}>
                <span><b>Author:&nbsp;</b>{record.author}&nbsp;|&nbsp;<b>Tracking Time:&nbsp;</b>{record.tracking_time}</span>
            </Grid>
            {/* Row 1 */}
            <Grid container spacing={2}>
                <Grid item xs={6}>
                    <SelectInput source="issue_category" validate={required()} choices={dictionary.listErrors} translateChoice={false} resettable />
                </Grid>
                <Grid item xs={6}>
                    <SelectInput source="action" choices={dictionary.listActions} translateChoice={false} resettable />
                </Grid>
            </Grid>
            {/* Row 2 */}
            <Grid item container xs={12}>
                <TextInput source="description" placeholder="Input description for issue category" value={record.description} multiline minRows={2} resettable />
            </Grid>
            <Grid container justifyContent={"flex-end"} spacing={1} style={{ marginBottom: -30 }}>
                <Grid item><SaveButton variant="contained" color="primary" label={'Save Tracking'} /></Grid>
                <Grid item><Button onClick={handleClose} variant='outlined' aria-label={'h_isOpen'}>Close</Button></Grid>
            </Grid>
        </>
    );
}

const TrackingProblemPopup = ({ vendor, dataProvider, data, callback }) => {
    const { order } = data;
    const [isOpen, setOpen] = useState(false);
    const [isLoading, setLoading] = useState(false);
    const [trackingItem, setTrackingItem] = useState({});
    const [listError, setListError] = useState([]);
    const notify = useNotify();
    const saveCallback = data.dlgState.callback || callback;

    useEffect(() => {
        if (isOpen) {
            let trackingErrors = (order.orderProcessTrackingErrors || []).filter(x => x.category == TRACKING_NAME).map(x => x.error);
            setListError(trackingErrors);
            let record = {
                order_id: order.id,
                order_number: order.order_number
            };
            if (order.orderProblemTracking) {
                let _error = order.orderProblemTracking.error;
                let issue_category, description;
                if (_error) {
                    var tmp = _error.split(':');
                    issue_category = tmp[0];
                    if (tmp[1]) description = tmp[1].trim();
                }
                record = {
                    ...record,
                    id: order.orderProblemTracking.id,
                    author: order.orderProblemTracking.author,
                    tracking_time: order.orderProblemTracking.utc_Posted_On,
                    issue_category,
                    description,
                    action: order.orderProblemTracking.action,
                    category: TRACKING_NAME
                };
                record.tracking_time = record.tracking_time ? toLocalTime(record.tracking_time).format('MMM D YYYY hh:mm A') : '';
            }
            setTrackingItem(record);
        }

    }, [isOpen]);

    const onSubmit = (data) => {
        let problemData = {
            id: data.id,
            order_id: data.order_id,
            order_number: data.order_number,
            action: data.action,
            error: data.description ? data.issue_category + ': ' + data.description : data.issue_category,
            category: TRACKING_NAME
        };
        setLoading(true);
        dataProvider.postData(vendor.controller, `SaveProblemTracking`, problemData)
            .then((response) => {
                setLoading(false);
                if (response && response.data.success) {
                    onClose(null, 'h_isOpen', false);
                    notify(`Saved Tracking successfully!`, { type: 'success', autoHideDuration: 1000 });
                    saveCallback(response.data.orderProblem);
                }
            });
    }

    const onClose = () => setOpen(false);

    return (
        <>
            {data.dlgState.btn_problemTracking &&
                <IconButton title="Problem Tracking" size="small"
                    onClick={() => setOpen(true)}
                    sx={{
                        borderRadius: '4px',
                        background: '#d9534f', '&:hover': {
                            backgroundColor: 'red',
                        },
                        marginLeft: '3px'
                    }}><ErrorIcon />
                </IconButton>}
            <Create title={" "} record={trackingItem}>
                <MDialog
                    title={`PROBLEM ORDER TRACKING #${trackingItem.order_number}`}
                    maxWidth={'md'}
                    open={isOpen}
                    handleClose={onClose}
                    name={'prolemTrk_isOpen'}
                    footer={false}
                    disabled={isLoading}
                    msgLoading={'Saving...'}
                    className={'order-dialog'}
                    children={
                        <SimpleForm defaultValues={order.orderProcessTracking || {}} toolbar={null} mode="onBlur" reValidateMode="onBlur" onSubmit={onSubmit}>
                            <FormContent
                                handleClose={onClose}
                                data={{
                                    record: trackingItem,
                                    processTrackingDics: order.orderProcessTrackingDictionaries || {},
                                    processTrackingErrs: listError
                                }}
                            />
                        </SimpleForm>
                    }
                >
                </MDialog>
            </Create>
        </>
    )
}
export default TrackingProblemPopup;