import * as React from 'react';
import { Menu, MenuItem, Button, Divider } from '@mui/material';
import { Link } from 'react-router-dom';

const MenuBarButton = ({ label, menuItems, open, onOpen, onClose, anchorEl }) => (
    <div style={{ cursor: 'pointer' }}>
        <Button ref={anchorEl} onClick={onOpen} aria-haspopup="true" style={{ textTransform: 'none', cursor: 'pointer' }}>{label}</Button>
        <Menu
            open={open}
            onClose={onClose}
            anchorEl={anchorEl.current}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
            transformOrigin={{ vertical: 'top', horizontal: 'left' }}
        >
            {menuItems.map(({ label, to, divider }, index) => (
                divider ? <Divider key={`divider-${index}`} /> : (
                    <MenuItem key={index} component={Link} to={to} onClick={onClose} style={{ cursor: 'pointer' }}>
                        {label}
                    </MenuItem>
                )
            ))}
        </Menu>
    </div>
);

export default function MenuToolbarExample() {
    const [menuIndex, setMenuIndex] = React.useState(null);
    const menuRefs = React.useRef([]);

    const handleOpen = (index) => setMenuIndex(index);
    const handleClose = () => setMenuIndex(null);

    const menus = [
        {
            label: "Analysis",
            menuItems: [
                { label: "SM Event Tickets", to: "/SmEventTickets/" },
                { label: "Order Detail Analysis", to: "/SingleEventAnalysis/" },
                { label: "TN Sales Analysis", to: "/ExchangeTicketCount/" }
            ]
        },
        {
            label: "Orders",
            menuItems: [
                // { label: "Vivid Order", to: "/Vivid/" },
                // { label: "TicketNetwork Order", to: "/TicketNetwork/" },
                // { label: "GoTickets Order", to: "/GoTickets/" },
                // { divider: true },
                { label: "Shipping Report", to: "/ShippingReport/" },
                { label: "Order Mistaken Report", to: "/OrderMistakeReport/" },
                { label: "Order Auto Price Report", to: "/OrderAutoPriceReport/" },
                { label: "One Call Now Report", to: "/OneCallNow/" },
                { divider: true },
                { label: "Order Adjust Rule", to: "/OrderAdjustRule/" }
            ]
        },
        {
            label: "Listings",
            menuItems: [
                { label: "Listings", to: "/Listings/" },
                { label: "Event Delivery", to: "/EventDelivery/" },
                { label: "Row Range for SPEC", to: "/NgaVenueConfig/" },
                { divider: true },
                { label: "Event Mapping", to: "/ListingsMapping/" },
                { label: "Event Matcher", to: "/EventMatcher/" },
                { divider: true },
                { label: "List variable", to: "/ListingVariable/" },
                { label: "Source Variables", to: "/SourceVariable/" }
            ]
        },
        {
            label: "Reports",
            menuItems: [
                { label: "Divvy Transactions", to: "/DivvyTransaction/" },
                { label: "Emburse Transactions", to: "/EmburseTransaction/" },
                { divider: true },
                { label: "Order Summary Report", to: "/OrderSummaryReport/" },
                { label: "Order Entry Report", to: "/OrderEntryReport/" },
                { label: "Resale Orders Report", to: "/ResaleOrdersReport/" },
                { divider: true },
                { label: "Process Tracking Report", to: "/ProcessTrackingReport/" },
                { label: "Problem Order Tracking Report", to: "/ProblemOrderTrackingReport/" },
                { label: "Mapping Error Report", to: "/MappingErrorReport/" },
                { divider: true },
                { label: "Minute By Minute", to: "/MinuteByMinute/" },
                { label: "TN Competitive Analysis", to: "/TnCompetitiveAnalysis/" },
                { label: "TN Hourly Data", to: "/TNHourlyData/" },
                { label: "Last Minute Underserved Events", to: "/LastMinuteUnderservedEvents/" }
            ]
        },
        {
            label: "Tools",
            menuItems: [
                { label: "Email", to: "/Email/" },
                { label: "Browser Stealth", to: "/Stealth/" },
                { divider: true },
                { label: "Csv Merge", to: "/CsvMerge/" },
                { label: "Dynamic Adjustment Rule", to: "/DynamicAdjustRule/" },
                { label: "Event Comparison Tool", to: "/SmEventCheck/" },
                { divider: true },
                { label: "Receipts Counter", to: "/ReceiptsCounter/" },
                { label: "Ticket Search", to: "/SearchTicketReceipt/" },
                { label: "TM Processing Report", to: "/AccountReport/" },
            ]
        },
        {
            label: "Admins",
            menuItems: [
                { label: "Primary Accounts", to: "/PrimaryAccount/" },
                { label: "Vivid Peformance Tracking", to: "/VividPerformance/" },
                { divider: true },
                { label: "Users", to: "/User/" },
                { label: "Users Dashboard", to: "/UserDashboard/" },
                { label: "Weekly Working Time", to: "/WeeklyWorkTime/" },
                { label: "Order Assignments", to: "/OrderAssignments/" },
                { divider: true },
                { label: "AAT Primary Accounts", to: "/AAT_PrimaryAccount/" },
                // { label: "AAT Account Assignment", to: "/AAT_AccountAssignment/" },
            ]
        },
    ];

    return (
        <nav style={{ display: 'flex' }}>
            {menus.map((menu, index) => {
                if (!menuRefs.current[index]) menuRefs.current[index] = React.createRef();
                return (
                    <MenuBarButton
                        key={index}
                        label={menu.label}
                        menuItems={menu.menuItems}
                        open={menuIndex === index}
                        onOpen={() => handleOpen(index)}
                        onClose={handleClose}
                        anchorEl={menuRefs.current[index]}
                    />
                );
            })}
        </nav>
    );
}