import * as React from 'react';
import RenderControls from '../../components/ra-list/RenderControls';
import { Button } from '@mui/material';
import { useState, useEffect, useRef } from 'react';
import ActionForm from './components/ActionForm';
import RaList from '../../components/ra-list/RaList';
import {
    FunctionField,
    TextField,
    Datagrid,
    useDataProvider,
    BooleanField
} from 'react-admin';
import { loadSourceType, loadDeliveryMethod, loadAccountMaps, autoLoad } from '../../utils/common';

import { formatLocalTime } from "../../components/ex_dayjs";

var isEdit;
const OrderAdjustRuleList = () => {
    const dataProvider = useDataProvider();
    const refbtnFilter = useRef(null);
    const [open, setOpen] = useState(false);
    const [editData, setEditData] = useState(null);
    const [marketType, setMarketType] = useState([]);
    const [delivery, setDelivery] = useState([]);
    const [account, setAccount] = useState([]);
    const [sortBy] = useState(['modified desc']);
    const filters = [
        { component: 'number', name: 'id_eq', label: 'ID', xs: 1.5 },
        { component: 'text', name: 'name_contains', label: 'Name', xs: 1.5 },
        { component: 'text', name: 'createdby_contains', label: 'Created By', xs: 1 }
    ];

    useEffect(() => {
        loadSourceType(dataProvider, (response) => {
            let formattedMarketType = response.map(x => ({ name: x.id, label: x.name }));
            setMarketType(formattedMarketType);
        }, false);

        loadDeliveryMethod(dataProvider, (response) => {
            let delivery = response.map(x => ({ name: x.id + "", label: x.name }));
            setDelivery(delivery);
        });

        loadAccountMaps(dataProvider, (response) => {
            let accounts = response.map(x => ({ name: x.account_id, label: x.listing_account }));
            accounts = accounts.concat([{ name: 5, label: 'VV_RESALE' }, { name: 6, label: 'OTHER_RESALE' }]);
            setAccount(accounts);
        });
        autoLoad(refbtnFilter);
    }, [])

    const handleRowClick = (id, resource, record) => {
        isEdit = true;
        setEditData(record);
        setOpen(true);
    };

    const handleCreate = () => {
        isEdit = false;
        setEditData(null);
        setOpen(true);
    }
    const handleClose = () => {
        isEdit = false;
        setEditData(null);
        setOpen(false);
    };

    return (
        <>
            <RaList
                perPage={250}
                pagination={[100, 250, 500, 1000]}
                title={'Order Adjust Rules'}
                sortby={sortBy}
                filters={
                    <RenderControls
                        actions={<Button onClick={handleCreate}>New Rule</Button>}
                        controls={filters}
                        refFilter={refbtnFilter}
                        exportFile={{
                            filename: `OrderAdjustRule-${formatLocalTime(new Date(), 'YY-MM-DD')}`,
                            columns: ['id', 'name', 'condition', 'action', 'createdby', 'modified']
                        }}
                    />
                }

            >
                <Datagrid bulkActionButtons={false} rowClick={handleRowClick}>
                    <TextField label="ID" source="id" />
                    <TextField label="Name" source="name" />
                    <TextField label="Created By" source="createdby" />
                    <FunctionField label="Modified" source="modified" render={record => formatLocalTime(record.modified)} />
                    <BooleanField source="active" label={'Active'} />
                </Datagrid>
            </RaList>
            {open && <ActionForm isEdit={isEdit} open={open} onClose={handleClose} editData={editData} marketType={marketType} delivery={delivery} account={account} refbtnFilter={refbtnFilter} />}
        </>
    );
}

export default OrderAdjustRuleList;
