import React, { useState, ChangeEvent } from 'react';
import {
    Button, SimpleForm, TextInput, Toolbar, useRefresh, useDataProvider
} from 'react-admin';
import SearchIcon from '@mui/icons-material/Search';
import { Stack } from '@mui/material';
// Define the structure of the search result
export interface SearchResult {
    key: string;
    size: number;
}
// Define the props for the Filters component
interface FiltersProps {
    setBucketName: (bucketName: string) => void;
    setPrefix: (prefix: string) => void;
    handleSearchResult: (response: SearchResult[]) => void;
}

// Filters component
const Filters: React.FC<FiltersProps> = ({ setBucketName, setPrefix, handleSearchResult }) => {
    // State for bucket name
    const [bucket, setBucket] = useState<string>('song-availability');
    // State for prefix
    const [key, setKey] = useState<string>('exact/0D00615DDF612D2D/');
    // Refresh function from react-admin
    const refresh = useRefresh();
    const dataProvider = useDataProvider();

    // Handle search button click
    const handleSearch = async () => {
        setBucketName(bucket); // Set bucket name
        setPrefix(key); // Set prefix

        try {
            const response = await dataProvider.fetchData('wasabi', `list/${bucket}/${key}`);
            if (handleSearchResult)
                handleSearchResult(response.data); // Handle API response

        } catch (error) {
            console.error('Error fetching data:', error);
        }

        refresh(); // Refresh the page
    };

    // Handle input change for bucket name
    const handleBucketChange = (event: ChangeEvent<HTMLInputElement>) => {
        setBucket(event.target.value);
    };

    // Handle input change for prefix
    const handleKeyChange = (event: ChangeEvent<HTMLInputElement>) => {
        setKey(event.target.value);
    };

    return (
        <SimpleForm toolbar={false} onSubmit={handleSearch}>
            <Stack direction="row" justifyContent="space-between">
                <TextInput
                    disabled
                    label="Bucket Name"
                    source="bucket"
                    value={bucket}
                    defaultValue={bucket}
                    onChange={handleBucketChange}
                />
                <TextInput
                    label="Prefix"
                    source="prefix"
                    value={key}
                    defaultValue={key}
                    onChange={handleKeyChange}
                />
                <Toolbar>
                    <Button type="submit" label="Search">
                        <SearchIcon />
                    </Button>
                </Toolbar>
            </Stack>
        </SimpleForm>
    );
};
export default Filters;
