import React, { useState } from 'react';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import PropTypes from 'prop-types';
import { useNotify } from 'react-admin';

const defaultProps = {
    variant: 'contained',
    color: 'primary',
    text: 'Loading...'
}

const LoadingButton = ({ onClick, children, startIcon, disabled, text = defaultProps.text, variant = defaultProps.variant, color = defaultProps.color, ...props }) => {
    const [isLoading, setIsLoading] = useState(false);
    const notify = useNotify();

    const handleClick = async () => {
        if (isLoading) return; // Prevent multiple clicks while loading
        setIsLoading(true);
        try {
            if (onClick) await onClick();

            setIsLoading(false);
        } catch (error) {
            notify(`Error during promise execution: ${error}`, { type: 'error' });
            setIsLoading(false);
        }
    };

    return (
        <Button
            {...props}
            variant={variant}
            color={color}
            onClick={handleClick}
            disabled={isLoading ? true : disabled} // Disable the button when loading
            startIcon={isLoading ? <CircularProgress size={20} color="inherit" /> : startIcon || null} // Add loading spinner
            style={{ position: 'relative' }} // Position relative for better styling of the spinner
        >
            {isLoading ? text : children}
        </Button>
    );
};

// Define prop types for the component
LoadingButton.propTypes = {
    // promise: PropTypes.func.isRequired, // Expect a function that returns a promise
    onClick: PropTypes.func, // Expect a function that returns a promise
    children: PropTypes.node.isRequired, // Button text or elements
};

export default LoadingButton;