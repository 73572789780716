import React, { useState, useRef } from 'react';
import OrderBase from '../order-base';
import { create as createSpecificVendor } from '../order-base/vendor';


const ResaleOrder = () => {
    const specificVendor = createSpecificVendor('Resale');
    return (
        <OrderBase
            vendor={specificVendor}
        >

        </OrderBase>
    )
}
const ResaleOrderPage = {
    name: 'Resale',
    list: ResaleOrder
}
export default ResaleOrderPage;