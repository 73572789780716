import { Button, Checkbox, FormControlLabel, Grid, Stack, TextField, Divider, IconButton } from "@mui/material";
import React, { useState, useCallback, useRef } from "react";
import { MultipleStop, RemoveCircleOutline, Upload, UploadFile } from '@mui/icons-material';
import MDialog from "../../../../components/controls/MDialog";
import { useDropzone } from 'react-dropzone';
import { PDFDocument, StandardFonts, rgb } from "pdf-lib";
import jsQR from "jsqr";
import { useRecordContext } from "react-admin";
import { formatDateTime } from "../../../../components/ex_dayjs";
import { STATUS_ENUM } from "../../../../utils/enum";
import Tesseract from "tesseract.js";
import MCheckbox from "../../../../components/controls/MCheckbox";
import LoadingButton from "../../../../components/controls/LoadingButton";

const OrderShipping = (props) => {
    const { notify, dataProvider, vendor, validate, validComplete, openShipping, closePopup, setDlgState } = props;
    const order = useRecordContext();
    const controller = vendor.controller;
    if (!order) return;

    const [shippingPopup, setShippingPopup] = useState({ isUploadUrl: false, isBothUpload: false, open: false, showShippingBtn: false });
    const [previewPDF, setPreviewPDF] = useState({ width: '100%', height: '500px', blobURL: null, isMobileQR: false, filesNum: 0, selectedFiles: null });
    const [uploadBtn, setUploadBtn] = useState({ hide: false, unmatching: false, invalidText: false, showForceUpload: false, forceUpload: false })
    const [transferLink, setTransferLink] = useState([{ url: '' }, { url: '' }]);
    const fileInputRef = useRef(null);

    React.useEffect(() => {
        let event_market = order.event_market || '';
        let deliveryMethod = order.delivery_method_value;
        var isDirectTransfer = deliveryMethod == 5;
        var isWillCall = deliveryMethod == 6;
        var isHardTicket = deliveryMethod == 1;

        //Axs & Tdc & ticketweb: upload proof for DT
        var allowUploadUrl = isDirectTransfer && !["axs", "tdc", 'ticketweb'].some(s => event_market.toLowerCase() === s);
        // Exact: Delivery = Direct Transfer ? Upload ULRs
        var isBothUpload = allowUploadUrl && event_market == 'paciolan';        
        var isShowUploadPopup = allowUploadUrl || (!isWillCall && !isHardTicket)
        if (isShowUploadPopup) {
            setShippingPopup(prev => ({ ...prev, isUploadUrl: allowUploadUrl, isBothUpload: isBothUpload, showShippingBtn: true }));
        }

    }, [order.delivery_method_value]);

    React.useEffect(() => {
        setShippingPopup(prev => ({ ...prev, open: openShipping }));
    }, [openShipping])

    const closeShippingPopup = () => {
        setPreviewPDF(prev => ({ ...prev, blobURL: null, isMobileQR: false, filesNum: 0, selectedFiles: null }));
        setUploadBtn(prev => ({ ...prev, hide: false, unmatching: false, invalidText: false, showForceUpload: false, forceUpload: false }))
        setTransferLink([{ url: '' }, { url: '' }]);
        setShippingPopup(prev => ({ ...prev, open: false }));
        closePopup();
    };
    //#region upload file
    const onDrop = useCallback(async (acceptedFiles) => {
        setUploadBtn(prev => ({ ...prev, hide: true, showForceUpload: false }));
        await workWithFiles(acceptedFiles);
        setPreviewPDF(prev => ({ ...prev, selectedFiles: acceptedFiles, filesNum: acceptedFiles.length }));
    }, [previewPDF?.excludes, previewPDF?.isMobileQR]);

    async function workWithFiles(inputFiles) {
        let combinedFile = await combineFilesToPDF(inputFiles, getPageExcludes(previewPDF?.excludes));
        fileInputRef.current = combinedFile;
        let blobUrl = URL.createObjectURL(combinedFile) + '#zoom=FitWidth';
        setPreviewPDF(prev => ({ ...prev, blobURL: blobUrl }));
    }
    const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

    // Determine the image embedding method based on the file type
    function getImageEmbedMethod(type) {
        return new Promise((resolve) => {
            const fileType = type.toLowerCase();
            if (fileType === 'image/png') {
                resolve((doc, bytes) => doc.embedPng(bytes));
            } else if (fileType === 'image/jpeg' || fileType === 'image/jpg') {
                resolve((doc, bytes) => doc.embedJpg(bytes));
            } else {
                // Handle other image formats accordingly
                // For example, you can implement additional embedding methods for GIF, BMP, etc.
                // resolve((doc, bytes) => doc.embedGif(bytes));
                // resolve((doc, bytes) => doc.embedBmp(bytes));
                // ...

                // For unsupported formats, you can skip processing or display an error message
                console.error('Unsupported image format:', fileType);
                resolve(null);
            }
        });
    }

    function getPageExcludes(range) {
        const _excludes = [];
        if (!range) return;

        const parts = range.split(',');
        for (let part of parts) {
            part = part.trim();
            if (part.includes('-')) {
                const [start, end] = part.split('-').map(Number);
                for (let i = start; i <= end; i++) _excludes.push(i);
            } else {
                _excludes.push(Number(part));
            }
        }

        return _excludes;
    }

    const combineFilesToPDF = async (filesInput, excludePages = []) => {
        const readerPromises = [], arrQR = [], arrText = [];

        // const files = Array.from(fileInput.files);
        filesInput.forEach((file) => {
            const reader = new FileReader();
            const promise = new Promise((resolve) => {
                reader.onload = function () {
                    const pdfBytes = new Uint8Array(reader.result);
                    resolve({
                        name: file.name,
                        type: file.type,
                        data: pdfBytes,
                        file: file
                    });
                };
            });

            reader.readAsArrayBuffer(file);
            readerPromises.push(promise);
        });
        //===========================================================
        try {
            // Wait for all reader promises to resolve
            const results = await Promise.all(readerPromises);

            // Create a merged pdf
            const mergedPdf = await PDFDocument.create();

            // Combine files result into the merged PDF
            for (const result of results) {
                await combineFile(mergedPdf, result);
            }

            // Save the merged PDF
            const original = await mergedPdf.save();

            // Create a new modified PDF document
            const modifiedPDF = await PDFDocument.create();
            const loadedPDF = await PDFDocument.load(original);
            //get pages in original pdf
            const copiedPages = await modifiedPDF.copyPages(loadedPDF, loadedPDF.getPageIndices());

            // Add copied-pages to the modified-PDF, excluding specified pages (user enter them)
            for (let i = 0; i < copiedPages.length; i++) {
                if (excludePages.includes(i + 1)) continue;
                modifiedPDF.addPage(copiedPages[i]);
            }

            // Embed font in the modified PDF
            const font = await modifiedPDF.embedFont(StandardFonts.Helvetica);

            // Get the total number of pages in the modified PDF
            const pageCount = modifiedPDF.getPageCount();

            // Draw page numbers on each page
            for (let pageNum = 0; pageNum < pageCount; pageNum++) {
                const page = modifiedPDF.getPage(pageNum);
                const pageNumberText = `Page ${pageNum + 1}`;
                const { width, height } = page.getSize();
                const textSize = 10;
                const textWidth = font.widthOfTextAtSize(pageNumberText, textSize);
                const textHeight = font.heightAtSize(textSize);

                page.drawText(pageNumberText, {
                    x: (width - textWidth) / 2,
                    y: textHeight + 10, // Adjust the position as needed
                    size: textSize,
                    font: font,
                    color: rgb(0, 0, 0), // Black color
                });
            }

            // Check if the number of pages matches the number of tickets on the order
            let _unmatching = ![4, 5].some(x => x === order.delivery_method_value) && order.quantity !== pageCount;
            if (_unmatching) {
                notify('The number of pages must match the number of tickets on the order.', { type: 'error' });
            }
            setUploadBtn(prev => ({ ...prev, unmatching: _unmatching }));

            // Save the modified PDF and return as a blob
            const modifiedPdfBytes = await modifiedPDF.save();
            let blob = new Blob([modifiedPdfBytes], { type: 'application/pdf' });

            return blob;
        } catch (error) {
            console.error('Error merging and processing PDFs:', error);
            throw error; // Re-throw the error to be handled by the caller
        }
        //===========================================================   
        function combineFile(mergedPdf, fileData) {
            const { name, type, data, file } = fileData;
            if (type.includes('image')) {
                var canvas = document.createElement('canvas');
                var ctx = canvas.getContext('2d');
                // Create a new Image object
                var img = new Image();
                img.onload = function () {
                    canvas.width = img.width;
                    canvas.height = img.height;
                    ctx.drawImage(img, 0, 0);
                    // Decode QR code from the canvas
                    var imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
                    var code = jsQR(imageData.data, imageData.width, imageData.height);
                    if (code) {
                        console.log("QR Code detected:", code.data);
                        arrQR.push(code.data);
                        setPreviewPDF(prev => ({ ...prev, isMobileQR: true }))
                        // You can do further processing here
                    } else {
                        console.log("No QR Code detected");
                    }
                    //extract text from canvas
                    extractText(imageData);
                };
                img.src = URL.createObjectURL(file);

                return addImageToPDF(data);
            } else {
                setUploadBtn(prev => ({ ...prev, hide: false }));
                if (type === 'application/pdf') {
                    return addPDFToPDF(data);
                } else {
                    return Promise.resolve();
                }
            }

            function extractText(imageData) {
                //Combine multiple text
                removeBackgroundText(imageData)
                Tesseract.recognize(file, 'eng').then(_ => {
                    if (_.data != null) {
                        var text = _.data.text;
                        //console.log(text);
                        arrText.push(text);
                    }
                }, e => console.log(e));
            }

            function removeBackgroundText(imageData) {
                // Remove background before extract text
                // Convert to grayscale
                // The image is converted to grayscale, which simplifies the data
                const idata = imageData.data;
                for (let i = 0; i < idata.length; i += 4) {
                    const r = idata[i];
                    const g = idata[i + 1];
                    const b = idata[i + 2];
                    const gray = 0.299 * r + 0.587 * g + 0.114 * b;
                    idata[i] = idata[i + 1] = idata[i + 2] = gray;
                }
                ctx.putImageData(imageData, 0, 0);

                // Apply thresholding
                // Pixels are converted to either black or white based on a threshold value, effectively removing the background
                const threshold = 128; // Adjust as needed
                for (let i = 0; i < idata.length; i += 4) {
                    const gray = idata[i];
                    const value = gray > threshold ? 255 : 0;
                    idata[i] = idata[i + 1] = idata[i + 2] = value;
                }
                ctx.putImageData(imageData, 0, 0);

                canvas.toBlob(blob => {
                    Tesseract.recognize(
                        blob,
                        'eng'
                    ).then(_ => {
                        if (_.data != null) {
                            var text = _.data.text;
                            arrText.push(text);
                            verifyProofOfTransfer(arrText);
                        }
                    }, e => console.log(e))
                        .finally(_ => setUploadBtn(prev => ({ ...prev, hide: false })));;
                });
            }

            function addPDFToPDF(blob) {
                return new Promise((resolve, reject) => {
                    PDFDocument.load(blob, { ignoreEncryption: true })
                        .then((pdfDoc) => {
                            if (pdfDoc.isEncrypted) {
                                return removeRestriction(file)
                                    .then(newBlob => {
                                        return PDFDocument.load(newBlob).then(_ => mergedPdf.copyPages(_, _.getPageIndices()));
                                    });
                            }
                            return mergedPdf.copyPages(pdfDoc, pdfDoc.getPageIndices());
                        })
                        .then((copiedPages) => {
                            copiedPages.forEach((page) => {
                                mergedPdf.addPage(page);
                            });
                            resolve();
                        })
                        .catch(reject);
                });

                function removeRestriction(blob) {
                    const formData = new FormData();
                    formData.append("file", blob, 'tickets.pdf');
                    return dataProvider.postData(controller, `RemoveRestriction`, formData).then(res => res.data);
                }
            }

            function addImageToPDF() {
                return new Promise((resolve, reject) => {
                    getImageEmbedMethod(type)
                        .then((embedMethod) => {
                            if (embedMethod) {
                                embedMethod(mergedPdf, data).then((image) => {
                                    const page = mergedPdf.addPage();

                                    const pageWidth = page.getWidth();
                                    const pageHeight = page.getHeight();
                                    const imageWidth = image.width;
                                    const imageHeight = image.height;

                                    const scaleWidth = pageWidth / imageWidth;
                                    const scaleHeight = pageHeight / imageHeight;
                                    const scale = Math.min(scaleWidth, scaleHeight);

                                    const scaledWidth = imageWidth * scale;
                                    const scaledHeight = imageHeight * scale;

                                    const x = (pageWidth - scaledWidth) / 2; // Center horizontally
                                    const y = (pageHeight - scaledHeight) / 2; // Center vertically

                                    page.drawImage(image, {
                                        x: x,
                                        y: y,
                                        width: scaledWidth,
                                        height: scaledHeight,
                                    });
                                    // Add a label with the image name at the bottom of the page
                                    page.drawText(name, { x: 10, y: 10, size: 10 });
                                    resolve();
                                }).catch(reject);
                            } else {
                                console.error('Unsupported image format:', type);
                            }
                        }).catch(reject);
                });
            }
        }
    }
    //#endregion

    //#region verify text
    function verifyProofOfTransfer(arrText) {
        var orderNumber = window.currentOrder.order_number;
        var allowMarkets = ['axs'];
        if (arrText.length > 0 && allowMarkets.some(x => window.currentOrder.event_market == x)) {
            var customer = window.currentOrder.customer;
            if (customer) {
                //AXS: "You successfully transferred {QTY} tickets to {NAME} ({EMAIL}) for {EVENTNAME}" ...
                //Must matching: Event, Qty, Customer Name, Email ...               
                var transferText = arrText.join("\n****\n").toLowerCase();//.replace(/\s*\n\s*/g, ' ').trim();
                var textSupport = ['you successfully transferred', 'transferred tickets'];
                console.log(transferText);
                if (textSupport.some(t => transferText.includes(t))) {
                    let eventDetailsText = `
                                            Event: ${window.currentOrder.event_name}
                                            QTY: ${window.currentOrder.quantity}
                                            Customer Name: ${customer.Name}
                                            Customer Email: ${customer.Email}`;
                    var result = compareEventDetails(transferText, eventDetailsText);
                    console.log(result);
                    //nameMatches, emailMatches, eventMatches, qtyMatches
                    var valid = result.qtyMatches, message = '';
                    if (!valid) {
                        message += ' *invaid QTY';
                    }
                    else {
                        valid = result.eventMatches;
                        if (!valid) message += ` *invaid EVENT`;

                        valid = result.nameMatches || result.emailMatches;
                        if (!valid) message += ` *invaid ${!result.emailMatches ? `CUS_EMAIL` : `CUS_NAME`}`;
                    }
                    //Check same event | same name | email
                    if (!valid) {
                        notify(`Order #${orderNumber} - Unmatching proof of transfer: ` + message, { type: 'warning', autoHideDuration: 3000 });
                        setUploadBtn(prev => ({ ...prev, showForceUpload: true, invalidText: true }));
                        return false;
                    } else {
                        setUploadBtn(prev => ({ ...prev, showForceUpload: false, invalidText: false }));
                    }
                }
                else notify(`Order #${orderNumber} - Unsupported this format, skipped validate.`, { type: 'warning', autoHideDuration: 3000 });
            }
        }
        return true;

        //Comparison  for same event | cus name | email | exact QTY
        function compareEventDetails(transferText, eventDetailsText) {
            // Helper function to normalize and split text into words
            function splitWords(text) {
                if (typeof text !== 'string') {
                    return [];
                }
                return text.trim().toLowerCase()
                    // Replace special characters with spaces
                    .replace(/&/g, ' and ') // Replace '&' with 'and'
                    .replace(/[.,_]/g, ' ')  // Replace '.', ',', and '_' with spaces
                    .replace(/\band\b/g, ' ') // Replace the word 'and' with space
                    // Remove any other non-alphanumeric characters
                    .replace(/[^\w\s]/g, '')
                    .split(/\s+/);
            }

            function splitEmail(text) {
                if (typeof text !== 'string') {
                    return [];
                }
                return text.trim().toLowerCase()
                    // Replace special characters with spaces
                    .replace(/&/g, ' and ') // Replace '&' with 'and'
                    .replace(/[@]/g, ' ')  // Replace '@', ',', and '_' with spaces
                    // Remove any other non-alphanumeric characters
                    .replace(/[^\w\s]/g, '')
                    .split(/\s+/);
            }

            // Extract and normalize relevant words for event comparison
            function extractRelevantEventWords(eventText) {
                // Capture the event details text before any potential irrelevant segments
                let eventName = eventText.split(/\s-\s/)[0]; // Capture text up to the first '-'
                return splitWords(eventName);
            }

            // Extract details using regex
            let transferEventPattern = /for\s+(?<event>[\s\S]+?)(?:\s+at|\s+-|$)/i;
            let transferQtyPattern = /\s*(?<qty>\d+)(?:\s)?(?:t)?icket(?:s)?/i;
            //let transferNamePattern = /(?:t)?icket(?:s)?\s+to\s+(?<name>.+?)\s+\(/i;
            //let transferEmailPattern = /\((?<email>.+?)\)/i;
            let transferNameEmailRegex = /to\s+([\w\s]+)\s+\(?([a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+(?:\s+\w+)?)\)?\s+for/;

            let eventPattern = /Event:\s(?<event>.+)/i;
            let qtyPattern = /QTY:\s(?<qty>\d+)/i;
            let namePattern = /Customer Name\s?:\s(?<name>.+)/i;
            let emailPattern = /Customer Email\s?:\s(?<email>.+)/i;

            // Extracting values from transferText
            //let transferNameMatch = transferText.match(transferNamePattern);
            //let transferEmailMatch = transferText.match(transferEmailPattern);
            const match = transferText.match(transferNameEmailRegex);
            let transferName = '', transferEmail = '';
            if (match) {
                transferName = match[1].trim(); // Captured name
                transferEmail = match[2];       // Captured email
            }

            let transferEventMatch = transferText.match(transferEventPattern);
            let transferQtyMatch = transferText.match(transferQtyPattern);

            // Extracting values from eventDetailsText
            let eventMatch = eventDetailsText.match(eventPattern);
            let qtyMatch = eventDetailsText.match(qtyPattern);
            let nameMatch = eventDetailsText.match(namePattern);
            let emailMatch = eventDetailsText.match(emailPattern);

            // Extract groups with traditional checks
            //let transferName = transferNameMatch ? transferNameMatch[1] : '';
            //let transferEmail = transferEmailMatch ? transferEmailMatch[1] : '';
            let transferEvent = transferEventMatch ? transferEventMatch[1] : '';
            let transferQty = transferQtyMatch ? transferQtyMatch[1] : '';

            let eventName = eventMatch ? eventMatch[1] : '';
            let eventQty = qtyMatch ? qtyMatch[1] : ''; // Directly use the quantity value as string
            let eventCustomerName = nameMatch ? nameMatch[1] : '';
            let eventCustomerEmail = emailMatch ? emailMatch[1] : '';

            // Split extracted values into words for comparison
            let transferNameWords = splitWords(transferName);
            let transferEmailWords = splitEmail(transferEmail);
            let transferEventWords = splitWords(transferEvent);

            let eventNameWords = splitWords(eventCustomerName);
            let eventEmailWords = splitEmail(eventCustomerEmail);
            let eventEventWords = extractRelevantEventWords(eventName);

            // Helper function to check if all elements of a smaller array are contained in a larger array
            function wordsContained(largerArray, smallerArray) {
                //let largerSet = new Set(largerArray);
                //return smallerArray.every(word => largerSet.has(word));
                return smallerArray.every(word => largerArray.some(x => x.includes(word)));
            }

            // Log the words for debugging
            console.log("Transfer Name Words: ", transferNameWords);
            console.log("Event Name Words: ", eventNameWords);
            console.log("Transfer Email Words: ", transferEmailWords);
            console.log("Event Email Words: ", eventEmailWords);
            console.log("Transfer Event Words: ", transferEventWords);
            console.log("Event Event Words: ", eventEventWords);
            console.log("Transfer Quantity: ", transferQty);
            console.log("Event Quantity: ", eventQty);

            // Perform comparisons
            let nameMatches = wordsContained(eventNameWords, transferNameWords);
            let emailMatches = wordsContained(eventEmailWords, transferEmailWords);
            let eventMatches = wordsContained(eventEventWords, transferEventWords);
            let qtyMatches = transferQty === eventQty; // Direct comparison for quantity

            return {
                nameMatches,
                emailMatches,
                eventMatches,
                qtyMatches
            };
        }
    }
    //#endregion

    const showMobileQR = order.delivery_method_value == 3;
    const handleExcludes = (props) => {
        setPreviewPDF(prev => ({ ...prev, excludes: props.target.value }));
    }
    const handleKeyUpExclude = async (props) => {
        if (props.key == 'Enter') {
            await workWithFiles(previewPDF.selectedFiles);
        }
    }
    const deleteTransferLink = (props) => {
        let index = props.currentTarget.id.split('-')[1] / 1;
        setTransferLink(prev => prev.filter(x => prev.indexOf(x) != index));//id with index always reset after render, so this condition is true
    }
    // Upload button
    const uploadShipping = async () => {
        const file = fileInputRef.current;
        const links = transferLink.map(x => x.url).filter(x => x);
        let orderNumber = order.order_number;
        let isUploadUrl = (shippingPopup.isUploadUrl || shippingPopup.isBothUpload) && links.length > 0;

        if (!validateUpload()) return;

        if (isUploadUrl) {
            await dataProvider.postData(controller, `CompleteUrl?orderNumber=${orderNumber}`, links)
                .then(response => {
                    if (response?.data) {
                        notify(`${response.data.message}`, { type: response.data.success ? 'success' : 'error', autoHideDuration: 3000 });
                    } else {
                        notify(`ERROR: ${response?.message}`, { type: 'error', autoHideDuration: 3000 });
                    }
                })
                .catch(err => notify(`ERROR: ${err?.message}`, { type: 'error', autoHideDuration: 3000 }))
                .finally(_ => closePopup())
        } else {
            const formData = new FormData();
            formData.append('file', file);
            formData.append('orderNumber', orderNumber);
            formData.append('mobileQR', previewPDF?.isMobileQR);
            if (uploadBtn.forceUpload) {
                notify(`Order #${orderNumber} - you are skipping validate proof of transfer.`, { type: "warning", autoHideDuration: 3000 });
                formData.append('forceUpload', uploadBtn.forceUpload);
            }

            await dataProvider.postData(controller, 'CompleteETickets', formData)
                .then(response => {
                    if (response?.data) {
                        notify(`${response.data.message}`, { type: response.data.success ? 'success' : 'error', autoHideDuration: 3000 });
                    } else {
                        notify(`ERROR: ${response?.message}`, { type: 'error', autoHideDuration: 3000 });
                    }
                })
                .catch(err => notify(`Upload failed: ${err?.message}`, { type: 'error', autoHideDuration: 3000 }))
                .finally(_ => closePopup())
        }

        function validateUpload() {
            if (shippingPopup.isBothUpload) {
                if (links.length == 0 && !file) {
                    notify('Please upload URLs or Proofs', { type: 'warning', autoHideDuration: 2200 });
                    return false;
                }
            } else if (shippingPopup.isUploadUrl) {
                if (links.length == 0) {
                    notify('Please upload URLs', { type: 'warning', autoHideDuration: 2200 });
                    return false;
                }
            } else if (!file) {
                notify('Please upload Files', { type: 'warning', autoHideDuration: 2200 });
                return false;
            }
            return true;
        }
    }
    // Shipping button
    async function clickShippingButton() {
        await dataProvider.postData(controller, 'ValidateShipping', order)
            .then(response => {
                if (response.data) {
                    if (response.data.message) {
                        notify(`ERROR: ${response.data.message}`, { type: 'warning', autoHideDuration: 3000 });
                        if (response.data.message.includes('Re-synced customer')) {
                            setDlgState(prev => ({ ...prev, reload_order: !prev.reload_order }));
                        }
                    } else {
                        let isShowShipping = !validate('shipping');
                        if (isShowShipping) {
                            let internal_status = order.internal_status;
                            if (internal_status == STATUS_ENUM.Completed) {
                                if (!validComplete()) {
                                    return;
                                }
                            }
                            setShippingPopup(prev => ({ ...prev, open: true }));
                        }
                    }
                } else {
                    notify(`ERROR: ${response?.message}`, { type: 'error', autoHideDuration: 3000 });
                }

            })
            .catch(err => notify(`Validated shipping Error: ${err?.message}`, { type: 'error', autoHideDuration: 3000 }))
    }
    const disabledUploadButton = () => {
        if (!uploadBtn.showForceUpload) {
            return uploadBtn?.unmatching || uploadBtn?.invalidText || false;
        } else return !uploadBtn.forceUpload;
    }

    const gridUploadUrl = () => {
        return (
            <Grid container>
                <Grid item xs={12} sx={{ marginBottom: '7px' }}>
                    <b>Transfer links</b>
                </Grid>
                <>
                    {transferLink.map((tl, index) => {
                        return (
                            <Grid container spacing={1} sx={{ marginBottom: '5px' }} key={`${order.order_number}-trlink-${index}`}>
                                <Grid item xs={10}>
                                    <TextField value={tl.url} variant="outlined" margin="none" onChange={(props) => setTransferLink(prev => prev.map(obj =>
                                        prev.indexOf(obj) == transferLink.indexOf(tl) ? { ...obj, url: props.target.value } : obj
                                    ))} />
                                </Grid>
                                <Grid item xs={1} >
                                    {index == 0 && <Button variant="outlined" size="small" onClick={() => setTransferLink(prev => ([...prev, { url: '' }]))}>Add</Button>}
                                    {index > 1 && <IconButton id={`rmbtn-${index}`} onClick={deleteTransferLink} ><RemoveCircleOutline /></IconButton>}
                                </Grid>
                            </Grid>
                        )
                    })}
                </>
            </Grid>
        )
    }

    const gridUploadFile = () => {
        return (
            <Grid container>
                <Grid item xs={12}>
                    <b>Upload tickets, proof of transfer screenshots (acceptable: pdf, image)</b>
                </Grid>
                <Grid item xs={6} sx={{ marginTop: '7px', paddingRight: '5px', '&:hover': { cursor: 'pointer' } }}>
                    {/* upload pdf or img */}
                    <div {...getRootProps()} >
                        <input {...getInputProps()} />
                        {
                            <Stack direction='row' style={{ border: 'solid 0.5px gray', borderRadius: '4px', justifyContent: 'center' }}>
                                <UploadFile sx={{ marginTop: '6px' }} />
                                <p style={{ margin: '7px' }}>Click to select files {previewPDF.filesNum ? `(${previewPDF.filesNum})` : ''}</p>

                            </Stack>
                        }
                    </div>
                </Grid>
                <Grid item xs={4}>
                    <TextField placeholder="excludes: e.g. 1-5, 8, 11-13" variant="outlined" size="small" margin="none" sx={{ marginTop: '7px' }}
                        value={previewPDF.excludes || ''}
                        onChange={handleExcludes}
                        onKeyUp={handleKeyUpExclude}
                    />
                </Grid>
                <Grid item xs={2} sx={{ paddingLeft: '5px' }}>
                    {showMobileQR && <FormControlLabel label="Mobile QRs" control={<Checkbox checked={previewPDF.isMobileQR} sx={{ fontSize: '13px' }} />} />}
                </Grid>
            </Grid>
        )
    }

    return (
        <>
            {shippingPopup.showShippingBtn && <LoadingButton onClick={clickShippingButton} variant="contained" size="small" style={{ backgroundColor: '#5cc0de' }}
                startIcon={<MultipleStop />} children={"Shipping"} />}
            <MDialog
                title={`#${order.order_number} - ${shippingPopup.isBothUpload ? "Upload Proofs | URLs" : shippingPopup.isUploadUrl ? 'Upload URLs' : 'Upload Files'}`}
                maxWidth={'md'}
                open={shippingPopup.open}
                handleClose={closeShippingPopup}
                name={'order-shipping-dialog'}
                action={
                    <Stack direction="row">
                        {uploadBtn.showForceUpload && <MCheckbox id='cb_force_upload' onChange={(value) => setUploadBtn(prev => ({ ...prev, forceUpload: value }))} label="force upload" />}
                        {!uploadBtn.hide && <LoadingButton startIcon={<Upload />} children={"Upload"} variant="contained" size="small" color="primary"
                            onClick={uploadShipping} disabled={disabledUploadButton()} />}
                    </Stack>
                }
                className={'order-dialog'}
                children={
                    <Grid container spacing={1}>
                        <Grid item xs={8}>
                            <Grid container spacing={2}>
                                <Grid item xs={3} sx={{ textAlign: "right" }}>
                                    <b>Event</b>
                                </Grid>
                                <Grid item xs={9}>
                                    <span>{order?.event}</span>
                                </Grid>
                            </Grid>
                            <Grid container spacing={2}>
                                <Grid item xs={3} sx={{ textAlign: "right" }}>
                                    <b>Venue</b>
                                </Grid>
                                <Grid item xs={8} >
                                    <span>{order?.venue}</span>
                                </Grid>
                            </Grid>
                            <Grid container spacing={2}>
                                <Grid item xs={3} sx={{ textAlign: "right" }}>
                                    <b>Date</b>
                                </Grid>
                                <Grid item xs={9}>
                                    <span>{formatDateTime(order?.date, 'MMM DD YYYY hh:mm A')}</span>
                                </Grid>
                            </Grid>
                            {order?.customer?.name.trim() && <Grid container spacing={2}>
                                <Grid item xs={3} sx={{ textAlign: "right" }}>
                                    <b>Customer Name</b>
                                </Grid>
                                <Grid item xs={9}>
                                    <span>{`${order?.customer?.name}`}</span>
                                </Grid>
                            </Grid>}
                            {order?.customer?.email.trim() && <Grid container spacing={2}>
                                <Grid item xs={3} sx={{ textAlign: "right" }}>
                                    <b>Customer Email</b>
                                </Grid>
                                <Grid item xs={8} >
                                    <span>{order?.customer?.email}</span>
                                </Grid>
                            </Grid>}
                        </Grid>
                        <Grid item xs={4}>
                            <Grid container spacing={2}>
                                <Grid item xs={3} sx={{ textAlign: "right" }}>
                                    <b>Section</b>
                                </Grid>
                                <Grid item xs={9}>
                                    <span>{order?.section}</span>
                                </Grid>
                            </Grid>
                            <Grid container spacing={2}>
                                <Grid item xs={3} sx={{ textAlign: "right" }}>
                                    <b>Row</b>
                                </Grid>
                                <Grid item xs={8} >
                                    <span>{order?.row}</span>
                                </Grid>
                            </Grid>
                            <Grid container spacing={2}>
                                <Grid item xs={3} sx={{ textAlign: "right" }}>
                                    <b>Seat</b>
                                </Grid>
                                <Grid item xs={9}>
                                    <span>{order?.lo_seat ? `${order.lo_seat} - ${order.hi_seat}` : order?.seat}</span>
                                </Grid>
                            </Grid>
                            <Grid container spacing={2}>
                                <Grid item xs={3} sx={{ textAlign: "right" }}>
                                    <b>Qty</b>
                                </Grid>
                                <Grid item xs={9}>
                                    <span>{order?.quantity}</span>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <Divider />
                            <Grid container spacing={1} sx={{ marginTop: '10px' }}>
                                {shippingPopup.isBothUpload && gridUploadUrl()}
                                {shippingPopup.isBothUpload && gridUploadFile()}
                                {/* else not both-upload                                 */}
                                {!shippingPopup.isBothUpload && shippingPopup.isUploadUrl && gridUploadUrl()}
                                {!shippingPopup.isBothUpload && !shippingPopup.isUploadUrl && gridUploadFile()}
                            </Grid>
                        </Grid>
                        {previewPDF.blobURL && <Grid item xs={12}>
                            <embed src={previewPDF.blobURL} type={'application/pdf'} width={previewPDF.width} height={previewPDF.height}></embed>
                        </Grid>
                        }
                    </Grid>

                }
            >
            </MDialog>
        </>

    )
}
export default OrderShipping;